import { IOnboardingFlowPrescription } from 'interfaces/onboarding-flow'
import { isProviderInformationValid } from './provider'
import { isPharmacyInfoComplete } from './pharmacy'

/**
 * Checks if a prescription is complete
 * A prescription is considered complete if it has drug
 * details and pharmacy or provider details
 * @param {IOnboardingFlowPrescription} prescription
 * @returns {boolean}
 */
export function isPrescriptionComplete(prescription: IOnboardingFlowPrescription): boolean {
    const isPharmacyValid = isPharmacyInfoComplete(prescription.pharmacy)
    const isProviderValid = isProviderInformationValid(prescription.provider, {
        validatePhone: prescription.provider?.isManualEntry,
    })
    const isComplete = prescription.drug && (isPharmacyValid || isProviderValid)

    return isComplete
}
