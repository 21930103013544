import { setTimeout } from '@wry/context'
import { useEffect, useState } from 'react'
import { getObjectRemoveNils } from '../lib/util/data-utils'

export interface IDrugPriceQuery {
    drugId?: string
    drugIds?: string[]
    substanceId?: string
    quantity: number
    daysSupply: number
}

export interface IDrugPrice {
    drug_id: string
    quantity: number
    sustainability_fee: string
    price_per_unit: string
    total_price: string
    days_supply: number
    standard_total_price: string
    membership_total_price: string
}

export const DEFAULT_DRUG_QUANTITY = 30
export const DEFAULT_DRUG_DAYS_SUPPLY = 30

const drugPriceCache = {}

export const fetchDrugPrices = async (drugPriceSearch: IDrugPriceQuery) => {
    const cachedDrugPrices = drugPriceCache[JSON.stringify(drugPriceSearch)]
    if (cachedDrugPrices) {
        return { data: cachedDrugPrices, error: false }
    }

    const drugPriceUrl =
        `${process.env.BACKEND_ENDPOINT}/api/rx/drug-price/?` +
        new URLSearchParams(getObjectRemoveNils(drugPriceSearch) as Record<string, any>)
    const resp = await fetch(drugPriceUrl)
    if (resp.ok) {
        const data = await resp.json()
        drugPriceCache[JSON.stringify(drugPriceSearch)] = data
        return { data, error: false }
    } else {
        return {
            data: [],
            error: !resp.ok,
        }
    }
}

const useDrugPrice = ({ drugId, drugIds, substanceId, quantity, daysSupply }: IDrugPriceQuery) => {
    const [drugPriceLoadingError, setDrugPriceLoadingError] = useState(false)
    const [refetchDrugPrices, setRefetchDrugPrices] = useState<() => void>()
    const [drugPrices, setDrugPrices] = useState<IDrugPrice[]>([])
    const [loadingDrugPrices, setLoadingDrugPrices] = useState(false)

    useEffect(() => {
        // Short circuit if we don't have anything to search for
        if (![drugId, drugIds, substanceId].some(Boolean)) {
            setDrugPrices([])
            return
        }
        const loadDrugPrices = async () => {
            setLoadingDrugPrices(true)
            try {
                const drugPrices = await fetchDrugPrices({ drugId, drugIds, substanceId, quantity, daysSupply })
                const drugPricesList = Array.isArray(drugPrices.data)
                    ? drugPrices.data
                    : drugPrices.data
                    ? [drugPrices.data]
                    : []
                // Sort prices in ascending order to show lowest price first
                drugPricesList.sort((a, b) => a.total_price - b.total_price)
                setDrugPrices(drugPricesList)
                setDrugPriceLoadingError(drugPrices.error)
                setLoadingDrugPrices(false)
            } catch {
                setDrugPriceLoadingError(true)
            }
        }
        setRefetchDrugPrices(() => () => loadDrugPrices())
        const loadDrugPricesDebounce = setTimeout(loadDrugPrices, 300)
        return () => {
            clearTimeout(loadDrugPricesDebounce)
        }
    }, [drugId, drugIds, substanceId, quantity, daysSupply])

    return {
        drugPrices,
        drugPriceLoadingError,
        loadingDrugPrices,
        refetchDrugPrices,
    }
}

export default useDrugPrice
