import React from 'react'
import { IPrescriptionDrug } from 'interfaces/prescriptions/prescription-drug'
import { IDrugsGroupedByForm } from 'components/products/medicine-product/rx-medicine-product'
import { ISubstance } from 'interfaces/substance'

const useDrugSelection = (substance: ISubstance) => {
    const [drugFormName, setDrugFormName] = React.useState<string>()
    const [drug, setDrug] = React.useState<IPrescriptionDrug>()

    const drugsGroupedByForm = React.useMemo(() => {
        if (!substance) {
            return
        }
        // Group drugs by form type (e.g. pill, capsule, etc)
        const drugFormGroups = substance?.drugs?.reduce((drugForms: IDrugsGroupedByForm, drug: IPrescriptionDrug) => {
            const drugForm = drugForms[drug.details.drug_form_name]
            if (!drugForm) {
                drugForms[drug.details.drug_form_name] = [drug]
            } else {
                drugForm.push(drug)
            }
            return drugForms
        }, {})

        // Sort each group by drug strength
        Object.values(drugFormGroups).forEach((drugFormGroup) => {
            drugFormGroup.sort((a, b) => {
                const aStrengthNumber = parseFloat(a.details.drug_strength) || 0
                const bStrengthNumber = parseFloat(b.details.drug_strength) || 0
                return aStrengthNumber - bStrengthNumber
            })
        })
        return drugFormGroups
    }, [substance])

    // Reset drug form and drug when substance changes
    React.useEffect(() => {
        if (!drugsGroupedByForm || !Object.keys(drugsGroupedByForm).length) {
            setDrugFormName(null)
            setDrug(null)
            return
        }
        const drugFormKey = Object.keys(drugsGroupedByForm)[0]
        const drugFormGroup = drugsGroupedByForm[drugFormKey]
        setDrugFormName(drugFormKey)
        setDrug(drugFormGroup[0])
    }, [drugsGroupedByForm])

    // reset drug when drug form changes
    React.useEffect(() => {
        if (!drugsGroupedByForm) {
            setDrug(null)
            return
        }
        const drugFormGroup = drugsGroupedByForm[drugFormName]
        setDrug(drugFormGroup ? drugFormGroup[0] : null)
    }, [drugFormName, drugsGroupedByForm])

    return {
        selectedDrug: drug,
        setDrug,
        selectedDrugFormName: drugFormName,
        setDrugFormName,
        drugsGroupedByForm,
    }
}

export default useDrugSelection
