import * as React from 'react'
import { colors } from 'theme.cjs'

const MagnifyingGlassIcon = ({
    className = '',
    width = '18',
    height = '20',
    fill = colors.mineshaft,
} = {}): React.ReactElement => (
    <svg className={className} width={width} height={height} viewBox="0 0 18 20" fill="none">
        <path
            d="M17.7562 17.4533L14.3969 14.124C16.9464 10.9447 16.7064 6.26568 13.797 3.35631C12.2674 1.82664 10.2578 0.986816 8.09826 0.986816C5.93872 0.986816 3.89916 1.82664 2.36949 3.35631C0.839819 4.88598 0 6.89555 0 9.08507C0 11.2746 0.839819 13.2842 2.36949 14.8138C3.89916 16.3135 5.90873 17.1833 8.09826 17.1833C9.92787 17.1833 11.7275 16.5535 13.1372 15.4137L16.4665 18.743C16.6464 18.923 16.8564 19.0129 17.0963 19.0129C17.3363 19.0129 17.5762 18.923 17.7262 18.743C18.0861 18.3831 18.0861 17.8132 17.7562 17.4533ZM14.3969 9.08507C14.3969 10.7647 13.737 12.3544 12.5373 13.5241C11.3376 14.6939 9.7779 15.3837 8.09826 15.3837C6.41862 15.3837 4.82896 14.7239 3.65921 13.5241C2.48946 12.3244 1.79961 10.7647 1.79961 9.08507C1.79961 7.40544 2.45947 5.81578 3.65921 4.64603C4.85896 3.47628 6.38863 2.78643 8.09826 2.78643C9.7779 2.78643 11.3676 3.44629 12.5373 4.64603C13.7071 5.84577 14.3969 7.37544 14.3969 9.08507Z"
            fill={fill}
        />
    </svg>
)

export default MagnifyingGlassIcon
