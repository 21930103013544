export function SpinnerIcon({ strokeWidth = 4, fill = '#767676', height = 24, width = 24, className = '' } = {}) {
    return (
        <svg
            className={`animate-spin ${className}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height={height}
            width={width}
            viewBox="0 0 24 24"
        >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke={fill} strokeWidth={strokeWidth} />
            <path
                className="opacity-75"
                fill={fill}
                d="M4 12a8 8 0 0 1 8-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0 1 4 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
        </svg>
    )
}
