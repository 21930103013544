import { IOnboardingFlowPharmacy } from 'interfaces/onboarding-flow'

/**
 * Checks if pharmacy info in onboarding flow is complete
 * @param {IOnboardingFlowPharmacy} pharmacy
 * @returns {boolean}
 */
export function isPharmacyInfoComplete(pharmacy: IOnboardingFlowPharmacy): boolean {
    return Boolean(pharmacy?.phone_number)
}
