import { BILLING_FREQUENCY } from '../../enum/billing-frequency'
import { IUser } from 'interfaces/user'
import {
    onboardingFlowEmailQueryParamName,
    onboardingFlowFirstNameQueryParamName,
    onboardingFlowLastNameQueryParamName,
    onboardingFlowPhoneNumberQueryParamName,
} from '../../misc-variables'

// used to keep title of author when we
// only use their first name
// note: use lower case
const peopleTitles = ['dr.', 'dr']

export const getShortName = (name: string): string => {
    let shortName: string
    const nameSplit = name?.split(' ')

    if (peopleTitles.includes(nameSplit[0]?.toLowerCase())) {
        shortName = nameSplit[0] + ' ' + nameSplit[1]
    } else {
        shortName = nameSplit[0]
    }

    return shortName
}

/**
 * Deep clones an object
 * @param object object
 * @returns object
 */
export const deepClone = (obj: any): any => {
    if (typeof structuredClone === 'function') {
        return structuredClone(obj)
    }

    return JSON.parse(JSON.stringify(obj))
}

/**
 * Returns number of days from billing frequency
 * @param billingFrequency BILLING_FREQUENCY
 * @returns number
 */
export const getDaysFromBillingFrequency = (billingFrequency: BILLING_FREQUENCY): number => {
    switch (billingFrequency) {
        case BILLING_FREQUENCY.YEARLY:
            return 365
        case BILLING_FREQUENCY.MONTHLY:
            return 30
        case BILLING_FREQUENCY.DAILY:
            return 1
        default:
            return NaN
    }
}
/**
 * Ease in out quadratic function
 * check link for more - https://spicyyoghurt.com/tools/easing-functions
 * @param time number
 * @param start number
 * @param change number
 * @param duration bumber
 * @returns bumber
 */
export const easeInOutQuad = (time: number, start: number, change: number, duration: number): number => {
    if ((time /= duration / 2) < 1) return (change / 2) * time * time + start
    return (-change / 2) * (--time * (time - 2) - 1) + start
}

/**
 * Formats phone number to xxx-xxx-xxxx format
 * @param phoneNumber string
 * @returns string
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = cleanPhoneNumber(phoneNumber)

    // Extract three groups of digits
    const areaCode = cleaned.substring(0, 3)
    const firstPart = cleaned.substring(3, 6)
    const secondPart = cleaned.substring(6, 10)

    // Format the phone number as "xxx-xxx-xxxx"
    let formattedPhoneNumber = areaCode
    if (firstPart) {
        formattedPhoneNumber += `-${firstPart}`
    }
    if (secondPart) {
        formattedPhoneNumber += `-${secondPart}`
    }

    return formattedPhoneNumber
}

/**
 * Removes non digit characters and US country code
 */
export function cleanPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters from the input
    const cleaned = phoneNumber.replace(/\D/g, '')

    // Check if the phone number includes the country code
    const hasCountryCode = cleaned.length === 11 && cleaned.startsWith('1')

    return hasCountryCode ? cleaned.slice(1) : cleaned
}

/**
 * Splits name into first name and rest of the name
 * @param name string
 * @returns {firstName: string, restOfTheName: string}
 */
export function splitFullname(name: string): { firstName: string; restOfTheName: string } {
    // split name on the first occurrence of space character
    // "sujan bahadur basnet" => ["sujan", "bahadur basnet"]
    const [firstName, restOfTheName] = (name ?? '')?.split(/ (.*)/s)

    return {
        firstName,
        restOfTheName,
    }
}

/**
 * Formats name for personalized token
 * @param name String
 * @returns String[]
 */
export const getPersonalizedTokenName = (name: string): string[] => {
    let { firstName, restOfTheName } = splitFullname(name)

    const maxCharactersInLine = 16

    if (firstName) {
        firstName = firstName.trim()
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)

        if (firstName.length >= maxCharactersInLine) {
            firstName = firstName.slice(0, maxCharactersInLine) + '...'
        }
    }

    if (restOfTheName) {
        restOfTheName = restOfTheName.trim()
        const restOfTheNameSplit = restOfTheName.split(' ')
        const restOfTheNameSplitCapitalized = restOfTheNameSplit.map(
            (lName) => lName.charAt(0).toUpperCase() + lName.slice(1),
        )

        restOfTheName = restOfTheNameSplitCapitalized.join(' ')

        if (restOfTheName.length >= maxCharactersInLine) {
            restOfTheName = restOfTheName.slice(0, maxCharactersInLine) + '...'
        }
    }

    return [firstName, restOfTheName]
}

/**
 * Returns URL search params for onboarding flow
 * @param user IUser
 * @returns URLSearchParams
 */
export const getOnboardingFlowUserSearchParameters = (user: IUser): URLSearchParams => {
    const searchParams = new URLSearchParams()

    const { email, first_name, last_name, phone_number } = user ?? {}

    if (email) {
        searchParams.set(onboardingFlowEmailQueryParamName, email)
    }
    if (first_name) {
        searchParams.set(onboardingFlowFirstNameQueryParamName, first_name)
    }
    if (last_name) {
        searchParams.set(onboardingFlowLastNameQueryParamName, last_name)
    }
    if (phone_number) {
        searchParams.set(onboardingFlowPhoneNumberQueryParamName, phone_number)
    }

    return searchParams
}
