// matches all characters inside {} - is inclusive
export const wordInsideCurlyBracketsRegexp = /{[^}]*}/

// matches all characters inside [] - is inclusive
export const wordInsideSquareBracketsRegexp = /\[[^\]]*]/

// matches all characters inside () - is inclusive
export const wordsInsideParenthesisRegexp = /\(.+?\)/

// matches trailing hyphens: 'pleasant-morning-', 'exciting-afternoon--'
export const trailingHyphensRegexp = /-+$/
