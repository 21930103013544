import { setTimeout } from '@wry/context'
import { ISubstance } from 'interfaces/substance'
import { useEffect, useState } from 'react'
import { getObjectRemoveNils } from '../lib/util/data-utils'

export interface ISubstanceSearchQuery {
    name: string | undefined
    limit?: number
    order_by?: string
    exactMatch?: boolean
}

const substanceSearchCache = {}

export const fetchSubstances = async (substanceSearch: ISubstanceSearchQuery) => {
    const cachedSubstanceSearches = substanceSearchCache[JSON.stringify(substanceSearch)]
    if (cachedSubstanceSearches) {
        return { data: cachedSubstanceSearches, error: false }
    }

    const substanceSearchUrl =
        `${process.env.BACKEND_ENDPOINT}/api/rx/substance-search/?` +
        new URLSearchParams(getObjectRemoveNils(substanceSearch) as Record<string, any>)
    const resp = await fetch(substanceSearchUrl)
    if (resp.ok) {
        const data = await resp.json()
        substanceSearchCache[JSON.stringify(substanceSearch)] = data
        return { data, error: false }
    } else {
        return {
            data: [],
            error: !resp.ok,
        }
    }
}

export const fetchSubstance = async ({ substanceName }: { substanceName: string }): Promise<ISubstance> => {
    const { data: substances } = await fetchSubstances({ name: substanceName, limit: 1, exactMatch: true })
    return substances[0]
}

const useSubstanceSearch = ({ name, limit, order_by, exactMatch }: ISubstanceSearchQuery) => {
    const [substancesLoadingError, setSubstancesLoadingError] = useState(false)
    const [refetchSubstances, setRefetchSubstances] = useState<() => void>()
    const [substances, setSubstances] = useState<ISubstance[]>([])
    const [loadingSubstances, setLoadingSubstances] = useState(false)

    useEffect(() => {
        // Short circuit if we don't have anything to search for
        if (typeof name === 'undefined') {
            setSubstances([])
            return
        }
        const loadSubstances = async () => {
            setLoadingSubstances(true)
            setSubstancesLoadingError(false)
            try {
                const substances = await fetchSubstances({ name, limit, order_by, exactMatch })
                setSubstances(substances.data)
                setSubstancesLoadingError(substances.error)
            } catch {
                setSubstancesLoadingError(true)
            } finally {
                setLoadingSubstances(false)
            }
        }
        setRefetchSubstances(() => () => loadSubstances())
        const loadSubstancesDebounce = setTimeout(loadSubstances, 300)
        return () => {
            clearTimeout(loadSubstancesDebounce)
        }
    }, [name, limit, order_by, exactMatch])

    return {
        substances,
        substancesLoadingError,
        loadingSubstances,
        refetchSubstances,
    }
}

export default useSubstanceSearch
