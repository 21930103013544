export enum PRESCRIPTION_TRANSFER_STATUS {
    CREATED = 'created',
    SUBMITTED = 'submitted',
    COMPLETED = 'completed',
    FAILED = 'failed',
}

export enum PRESCRIPTION_TRANSFER_DERIVED_STATUS {
    ORDERED = 'ordered',
    PREORDER_PAYMENT_PENDING = 'preorder payment pending',
}
