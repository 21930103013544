import * as React from 'react'
import { IOnboardingFlowPrescription } from 'interfaces/onboarding-flow'
import globalManifest from 'data/global-manifest.json'
import { getFormattedNumberWithCurrency } from '@/lib/util/currency'
import { PrescriptionItem } from 'components/cart/cart-prescription-line-item'
import { SpinnerIcon } from 'icons/spinner'

interface IProps {
    comparePrice: number
    price: number
    prescriptions?: IOnboardingFlowPrescription[]
    loading?: boolean
    showCompareLabel?: boolean
    onRemovePrescription?: (prescription: IOnboardingFlowPrescription) => void
}

const { resources: globalUIResources } = globalManifest

export function PriceSavingsCallout({
    comparePrice,
    price,
    prescriptions,
    loading,
    showCompareLabel,
    onRemovePrescription,
}: IProps) {
    const savings = comparePrice - price

    return (
        <div className={`bg-blue-rx-darker px-2.5 pt-6 pb-3 rounded-[28px] relative ${loading ? 'opacity-75' : ''}`}>
            {savings > 0 && (
                <div className="bg-yellow px-1.5 py-0.5 absolute top-0 -translate-y-1/2 -translate-x-1/2 left-1/2 p-1.6 text-xs leading-140% -tracking-6 font-bold rounded-md">
                    {globalUIResources['membershipUpsellCardSaveTagLabel'].value}{' '}
                    {getFormattedNumberWithCurrency(savings)}
                </div>
            )}
            <div className="flex justify-center gap-10 text-center">
                <div className="text-gray-darker">
                    <div className="text-xs">{globalUIResources['industryPriceLabel'].value}</div>
                    <s className="text-2.75xl font-bold leading-110% mt-1 block">
                        {getFormattedNumberWithCurrency(comparePrice)}
                    </s>
                </div>
                <div className="text-black">
                    <div className="text-xs">{globalUIResources['memberPriceLabel'].value}</div>
                    <div className="text-2.75xl font-bold leading-110% mt-1">
                        {getFormattedNumberWithCurrency(price)}
                    </div>
                </div>
            </div>

            {/* medicines */}
            {prescriptions?.length > 0 && (
                <ul className="mt-3 flex flex-col gap-y-1">
                    {prescriptions.map((prescription) => {
                        return (
                            <li key={prescription.id}>
                                <PrescriptionItem
                                    title={`${prescription.substance.name} ${prescription.drug?.details?.drug_strength}`}
                                    price={Number(prescription.price?.membership_total_price)}
                                    compareAtPrice={Number(prescription.price?.standard_total_price)}
                                    onRemove={() => onRemovePrescription(prescription)}
                                    isRemovable={prescriptions.length > 1}
                                    className="!py-2.5"
                                    showCompareLabel={showCompareLabel}
                                />
                            </li>
                        )
                    })}
                </ul>
            )}

            {loading && <SpinnerIcon height={16} width={16} className="absolute right-5 top-5" />}
        </div>
    )
}
