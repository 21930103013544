export enum PRESCRIPTION_DERIVED_STATUS {
    ACTIVE = 'Active',
    TRANSFERRING = 'Transferring',
    PREPPING = 'Prepping',
    SHIPPED = 'Shipped',
    RENEWAL_IN_PROGRESS = 'Renewal In Progress',
    RENEWAL_NEEDED = 'Renewal Needed',
    ORDER_SOON = 'Order Soon',
    AVAILABLE_TO_ORDER = 'Available to Order',
    EXPIRED = 'Expired',
    INACTIVE = 'Inactive',
    ERROR = 'Error',
}
